import * as React from "react"
import { Helmet } from "react-helmet";
import ReactTypingEffect from 'react-typing-effect';
import { motion } from "framer-motion"
import $ from "jquery"

//styles
import "../styles/chris.scss"

const list = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}
const item = {
  hidden: { x: -100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delayChildren: 0,
      staggerChildren: 0,
      duration: 0.6,
      ease: ["circOut"],
    }
  }
}

const text = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: ["circOut"],
      duration: 0.6,
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

const fadeInDelayed = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 1 } }
}

// const fadeIn = {
//   hidden: { opacity: 0 },
//   visible: { opacity: 1, transition: { delay: 1 } }
// };



// markup
class IndexPage extends React.Component {

  componentDidMount() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 0) {
        $('.scroll-down').fadeOut();
      } else {
        $('.scroll-down').fadeIn();
      }
    });
  }

  render() {

    return (
      <React.Fragment>
        <Helmet>
          <meta charset="utf-8" />
          <title>Christopher Fernandes</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta name="theme-color"
            content="#ffffff"
            media=" (prefers-color-scheme: light)"></meta>
          <meta name="theme-color"
            content="#000000"
            media=" (prefers-color-scheme: dark)"></meta>
        </Helmet>
        <main>
          <title>Christopher Fernandes</title>
          <div class="wrapper">
            <motion.div class="introduction" initial={{ y: "10vh" }} animate={{ y: 0, transition: { ease: [0.87, 0, 0.13, 1], delay: 5, duration: 1 } }}>
              <h1>
                <ReactTypingEffect eraseDelay={10000} eraseSpeed={100} speed={100}
                  text={["hey there!", "hello!", "oh, hi!"]} cursorClassName="typingcursor" cursor="_"
                />
              </h1>
              <motion.p viewport={{ once: true }} whileInView={{ opacity: 1, transition: { delay: 5.5 } }}>I'm Christopher Fernandes <span>— designer & developer based in Bombay, India. Currently, head of design & front-end web + app developer at <a href="https://volvmedia.com">Volv.</a></span></motion.p>

            </motion.div>

            <motion.div viewport={{ once: true }} initial={{ y: "20px", opacity: 0 }} whileInView={{ y: "0px", opacity: 0.5, transition: { delay: 6, duration: 6, ease: ["circOut"], } }} class="scroll-down"><h1>scroll down</h1></motion.div>

            {/* <motion.div viewport={{ once: true }} whileInView={{ opacity: 1, transition: { delay: 0.3 } }} class="skills-head">
            <h3>here's everything I can do.</h3>
            <motion.div viewport={{ once: true }} class="border" whileInView={{ width: '100%', transition: { delay: 0.4, duration: 0.5 } }}></motion.div>
          </motion.div>

          <div class="skills-tab">
            <motion.ul initial="hidden" viewport={{ once: true }} whileInView="visible" variants={list}>
              <motion.li variants={item}>ui/ux design.</motion.li>
              <motion.li variants={item}>graphic design.</motion.li>
              <motion.li variants={item}>motion design.</motion.li>
              <motion.li variants={item}>web development.</motion.li>
              <motion.li variants={item}>app development.</motion.li>
            </motion.ul >
          </div >

          <motion.div viewport={{ once: true }} whileInView={{ opacity: 1, transition: { delay: 0.3 } }} class="skills-head">
            <h3>softwares + frameworks I actively work on.</h3>
            <motion.div class="border" viewport={{ once: true }} whileInView={{ width: '100%', transition: { delay: 0.4, duration: 0.5 } }}></motion.div>
          </motion.div>

          <div class="skills-tab">
            <motion.ul initial="hidden" viewport={{ once: true }} whileInView="visible" variants={list}>
              <motion.li variants={item}>figma.</motion.li>
              <motion.li variants={item}>flutter.</motion.li>
              <motion.li variants={item}>gatsby react.</motion.li >
              <motion.li variants={item}>framer motion.</motion.li >
              <motion.li variants={item}>HTML.</motion.li >
              <motion.li variants={item}>SCSS.</motion.li >
              <motion.li variants={item}>vs code.</motion.li >
              <motion.li variants={item}>after effects.</motion.li >
              <motion.li variants={item}>photoshop.</motion.li >
            </motion.ul >
          </div > */}

            <motion.div viewport={{ once: false }} whileInView={{ opacity: 1, transition: { delay: 0.3 } }} class="skills-head">
              <motion.ul viewport={{ once: false }} initial="hidden" whileInView="visible" variants={list}>
                <motion.li variants={text}><h3>favorite&nbsp;</h3></motion.li>
                <motion.li variants={text}><h3> projects.</h3></motion.li>
              </motion.ul>

              <motion.div viewport={{ once: false }} class="border" whileInView={{ width: '100%', transition: { delay: 0.4, duration: 0.5 } }}></motion.div>
            </motion.div>

            <div class="skills-tab">
              <motion.ul viewport={{ once: false }} initial="hidden" whileInView="visible" variants={list}>
                <motion.li variants={item}><a rel="noreferrer" target="_black" href="https://apps.apple.com/us/app/volv-read-less-know-more/id1474813657?ls=1">volv app. <span>design + refinement in code.</span></a></motion.li>
                <motion.li variants={item}><a rel="noreferrer" target="_blank" href="https://volvmedia.com/">volv website. <span>design + code.</span></a></motion.li>
                <motion.li variants={item}><a rel="noreferrer" target="_blank" href="https://www.youtube.com/watch?v=1E1OFlTbuZ8">volv promo video. <span>motion graphics.</span></a></motion.li>
                <motion.li variants={item}><a rel="noreferrer" target="_blank" href="https://www.vazirani-automotive.com/">vazirani automotive website. <span>code.</span></a></motion.li >
                <motion.li variants={item}><a rel="noreferrer" target="_blank" href="https://www.youtube.com/watch?v=JAJkW6L4vMQ">microsoft surface band concept. <span>motion graphics.</span></a></motion.li >
                <motion.li variants={item}><a rel="noreferrer" target="_blank" href="https://t.co/FZska4EJ1h">prayer assistant app. <span>design + code.</span></a></motion.li >
                <motion.li variants={item}><a rel="noreferrer" target="_blank" href="https://www.youtube.com/ChristopherFernandes">creative lyrical videos on my youtube channel w/ about 2 million views. <span>motion graphics.</span></a></motion.li >
                <motion.li variants={item}>and this website :)</motion.li >
              </motion.ul>
            </div >

            <div class="footer" viewport={{ once: false }} initial="hidden" whileInView="visible" variants={fadeInDelayed}>
              <p><b>Get in touch:</b><br /></p>
              <p><a rel="noreferrer" target="_blank" href="https://twitter.com/@christoph_fer">X.com/@christoph_fer</a>
                <br /><a rel="noreferrer" target="_blank" href="https://instagram.com/@christoph_fer">Instagram.com/@christoph_fer</a>
                <br /><a rel="noreferrer" target="_blank" href="mailto:christoph_fer@outlook.com"> christoph_fer@outlook.com</a></p>

              <h4>Thanks for visiting. Have a nice one!</h4>
            </div>



            {/* <div class="portfolio-list">
            <motion.div class="portfolio-list-item" variants={fadeInDelayed} initial="hidden" whileInView="visible">
              <h1>Volv – A calm reader app that gives you the best content on the internet in 9 second reads. </h1>
            </motion.div>
          </div> */}

          </div >
        </main >
      </React.Fragment >

    )
  }
}


export default IndexPage
